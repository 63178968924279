import React  from "react"

export const Duration = ({ time }) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time - (hours * 3600)) / 60);
  const seconds = time - (hours * 3600) - (minutes * 60);
  
  return (
    <span className="duration">
      {hours > 0 && `${hours < 10 && "0"}${hours}:`}
      {minutes < 10 && "0"}{minutes}:
      {seconds < 10 && "0"}{seconds}
    </span>
  )
}