import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import ShowsListItem from "./shows-list-item"

const ShowsList = () => {
  const data = useStaticQuery(graphql`
    query {
      allNodeEmission(
        filter: {status: {eq: true}},
        sort: {fields: field_poids, order: DESC},
        limit: 3
      ) {
        edges {
          node {
            id
            title
            created(formatString: "DD MMMM YYYY", locale: "fr")
            body {
              summary,
              value
            }
            path {
              alias
            }
            relationships {
              image: field_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 670, maxHeight: 400, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <section id="shows-list">
      <h2 className="title is-2">Nos émissions</h2>
      <div className="columns is-multiline">
        {data.allNodeEmission.edges.map(({ node }, index) => (
          <ShowsListItem node={node} key={index} columnType={4}/>
        ))}
      </div>
      <div className="more-link">
        <Link to="/emissions" className="button is-rounded">Toutes nos émissions</Link>
      </div>
    </section>
  )
}

export default ShowsList
