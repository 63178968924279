import React from "react"

import Layout from "../components/layout"
import NewsList from "../components/news-list"
import PodcastsList from "../components/podcasts-list"
import ShowsList from "../components/shows-list"
import AnimationsSlider from '../components/animations-slider'
import { HomeLastTracks } from "../components/home-last-tracks"
// import { LastCompetition } from "../components/last-competition"
import { LastVideo } from "../components/last-video"
import SEO from "../components/seo";

const IndexPage = () => (
  <Layout>
    <SEO title="Accueil" />
    <h1 style={{display: "none"}}>Fugi FM</h1>
      <div className="container">
        <section className="section">
          <div className="columns is-multiline">
            <div className="column is-8-widescreen is-12">
              <AnimationsSlider autoplay autoplaySpeed={10000}/>
            </div>
            <div className="column is-4-widescreen is-12">
              <NewsList/>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="columns is-multiline">
            <div className="column is-12">
              <HomeLastTracks/>
            </div>
            {/*<div className="column is-5-widescreen is-7">*/}
            {/*  <LastCompetition/>*/}
            {/*</div>*/}
          </div>
        </section>
        <section className="section">
          <div className="columns is-multiline">
            <div className="column is-5-widescreen is-12">
              <PodcastsList />
            </div>
            <div className="column is-7-widescreen is-12">
              <LastVideo />
            </div>
          </div>
        </section>
        <div className="section">
          <div className="columns">
            <div className="column is-12">
              <ShowsList/>
            </div>
          </div>
        </div>
      </div>
  </Layout>
)

export default IndexPage
