import React from "react"
import Truncate from "./truncate";
import { Link } from "gatsby"
import PropTypes from "prop-types";

const NewsListItem = ({node, columnType, textLimit, cssTruncate, moreLink, withSummary}) => {
  return (
    <div className={`column is-` + columnType}>
      <article className="news">
        <span className="date">{node.created}</span>
        <h3 className="title is-3"><Link to={node.path.alias}>{node.title}</Link></h3>
        {withSummary && (
          <div className="content">
            <Truncate
                className="body"
                css={cssTruncate}
                limit={textLimit}
                html={(
                  node.body.summary.length > 0
                    ? node.body.summary
                    : node.body.value
                )}
              />

            {moreLink && (
                <Link to={node.path.alias} className="button button-more is-rounded">En savoir plus</Link>
            )}
          </div>
        )}
      </article>
    </div>
  )
}

NewsListItem.propTypes = {
  node: PropTypes.object.isRequired,
  columnType: PropTypes.number,
  textLimit: PropTypes.number,
  cssTruncate: PropTypes.bool,
  moreLink: PropTypes.bool,
  withSummary: PropTypes.bool
}

NewsListItem.defaultProps = {
  columnType: 12,
  textLimit: 200,
  cssTruncate: true,
  moreLink: false,
  withSummary: true,
}

export default NewsListItem
