import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Truncate from "./truncate";
import PropTypes from "prop-types";

const ShowsListItem = ({node, columnType, textLimit, cssTruncate}) => {
  return (
    <div className={`column is-${columnType}`}>
      {node.relationships.image.localFile && (
        <Link to={node.path.alias} className="image-link">
          <Img
            fluid={node.relationships.image.localFile.childImageSharp.fluid}
            alt={node.title}
          />
        </Link>
      )}
      <div className="content">
        <h3 className="title is-3">
          <Link to={node.path.alias}>{node.title}</Link>
        </h3>

        <Truncate
          className="body"
          css={cssTruncate}
          limit={textLimit}
          html={(
            node.body.summary.length > 0
              ? node.body.summary
              : node.body.value
          )}
        />
      </div>
    </div>
  )
}

ShowsListItem.propTypes = {
  node: PropTypes.object.isRequired,
  columnType: PropTypes.number,
  textLimit: PropTypes.number,
  cssTruncate: PropTypes.bool,
}

ShowsListItem.defaultProps = {
  columnType: 12,
  textLimit: 200,
  cssTruncate: true,
}

export default ShowsListItem
