import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import PodcastListItem from "./podcast-list-item"

const PodcastsList = () => {
  const data = useStaticQuery(graphql`
    query {
      allNodePodcast(
        filter: { status: { eq: true } }
        sort: { fields: created, order: DESC }
        limit: 2
      ) {
        edges {
          node {
            id
            title
            created(formatString: "DD MMMM YYYY", locale: "fr")
            field_duree {
              seconds
            }
            path {
              alias
            }
            relationships {
              image: field_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 260, maxHeight: 180, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                    sizes {
                      presentationWidth
                      presentationHeight
                    }
                  }
                }
              }
              audio: field_audio {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <section id="podcasts-list">
      <h2 className="title is-2">Infos locales</h2>
      {data.allNodePodcast.edges.length > 0 ? (
        <>
          <div className="columns is-multiline">
            {data.allNodePodcast.edges.map(({ node }, index) => (
              <PodcastListItem
                id={node.id}
                node={node}
                columnType={6}
                columnTypeWidescreen={12}
                key={index}
              />
            ))}
          </div>
          <div className="more-link">
            <Link to="/infos-locales" className="button is-rounded">
              Toutes nos infos
            </Link>
          </div>
        </>
      ) : (
        <p>Aucun contenu pour le moment&hellip;</p>
      )}
    </section>
  )
}

export default PodcastsList
