import React from "react"
import Truncate from "./truncate";
import { graphql, Link, useStaticQuery } from "gatsby"
import YoutubeVideo from "./youtube-video"

export const LastVideo = () => {
  const data = useStaticQuery(graphql`
    {
      video: allNodeVideo(filter: {status: {eq: true}}, sort: {fields: created, order: DESC}, limit: 1) {
        last: nodes {
          id
          title
          body {
            value
          }
          field_video {
            uri
          }
          path {
            alias
          }
        }
      }
    }
  `)

  return (
    <div id="videos-list">
      <h2 className="title is-2">Nos invités en vidéo</h2>
      <div className="columns">
        <div className="column is-12">
          <Link to={data.video.last[0].path.alias}>
            <YoutubeVideo url={data.video.last[0].field_video.uri} thumbnail={true}/>
          </Link>
          <h3 className="title is-3">
            <Link to={data.video.last[0].path.alias}>{data.video.last[0].title}</Link>
          </h3>
          {data.video.last[0].body && (
            <Truncate css={true} className="video-body" limit={200} html={data.video.last[0].body.value}/>
          )}
        </div>
      </div>

      <div className="more-link">
        <Link className="button is-rounded" to="/videos">Toutes nos vidéos</Link>
      </div>
    </div>
  )
}
