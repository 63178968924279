import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Swipeable } from "react-swipeable"
import MenuLink from "./menu-link";
import PropTypes from 'prop-types'

const AnimationsSlider = ({autoplay, autoplaySpeed}) => {
  const data = useStaticQuery(graphql`
    query {
      allNodeAnimationCommerciale(filter: {status: {eq: true}}, sort: {fields: created, order: DESC}, limit: 4) {
        edges {
          node {
            id
            title
            created(formatString: "DD MMMM YYYY", locale: "fr")
            field_afficher_infos
            field_lien {
              uri
            }
            relationships {
              image: field_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 890, maxHeight: 540, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [activeIndex, setActiveIndex] = useState(0)
  const [autoplayTimeout, setAutoplayTimeout] = useState(null)

  let totalSlides = data.allNodeAnimationCommerciale.edges.length
  let lastSlideIndex = totalSlides - 1

  useEffect(() => {
    if (autoplay) {
      if (autoplayTimeout) {
        clearTimeout(autoplayTimeout)
      }

      setAutoplayTimeout(setTimeout(() => {
        if (activeIndex + 1 <= lastSlideIndex) {
          setActiveIndex(activeIndex + 1)
        } else {
          setActiveIndex(0)
        }
      }, autoplaySpeed))
    }
  }, [activeIndex])

  return (
    <div id="animations-list">
      {data.allNodeAnimationCommerciale.edges.map(({ node }, index) => (
        <div key={index} className="animation-item" data-index={index} data-active={(activeIndex === index)}>
          <Swipeable
            trackMouse={true}
            preventDefaultTouchmoveEvent={true}
            className="swipeable"
            onSwipedLeft={() => {
              setActiveIndex((index === lastSlideIndex) ? 0 : index + 1)
            }}
            onSwipedRight={() => {
              setActiveIndex((index === 0) ? lastSlideIndex : index - 1)
            }}
          >
            {node.field_lien ? (
              <>
                {/* MenuLink usage to handle external || internal || entity links */}
                <MenuLink internalId={node.field_lien.uri}>
                  <Img
                    fluid={node.relationships.image.localFile.childImageSharp.fluid}
                    alt={node.title}
                  />
                  {node.field_afficher_infos && (
                    <div className="animation-details">
                      <div className="animation-title">{node.title}</div>
                    </div>
                  )}
                </MenuLink>
              </>
            ) : (
              <>
                <Img
                  fluid={node.relationships.image.localFile.childImageSharp.fluid}
                  alt={node.title}
                />
                {node.field_afficher_infos && (
                  <div className="animation-details">
                    <div className="animation-title">{node.title}</div>
                    {node.field_lien && (
                      <div className="animation-more-link">En savoir plus</div>
                    )}
                  </div>
                )}
              </>
            )}
          </Swipeable>
        </div>
      ))}
      {(data.allNodeAnimationCommerciale.edges.length > 1) && (
        <ul className="dots">
          {data.allNodeAnimationCommerciale.edges.map(({ node }, index) => (
            <li key={index} className="dot" data-active={(activeIndex === index)}>
              <button onClick={() => setActiveIndex(index)}>{index}</button>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

AnimationsSlider.propTypes = {
  autoplay: PropTypes.bool,
  autoplaySpeed: PropTypes.number
}

AnimationsSlider.defaultProps = {
  autoplay: false,
  autoplaySpeed: 5000
}

export default AnimationsSlider
