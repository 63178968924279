import React from "react"
import PropTypes from "prop-types";

const Truncate = ({html, limit, css, ...props}) => {
  const cssTruncate = {
    width: "100%",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  }

  let stripped = html.replace(/(<([^>]+)>)/ig,"")
  let truncated = (limit > 0) ? stripped.substr(0, limit) + "&hellip;" : stripped


  return (
    <div {...props}>
      <span
        className="truncated"
        dangerouslySetInnerHTML={{ __html : truncated }}
        style={css ? cssTruncate : {}}
      />
    </div>
  )
}

Truncate.propTypes = {
  html: PropTypes.node.isRequired,
  limit: PropTypes.number,
  css: PropTypes.bool,
}

Truncate.defaultProps = {
  limit: 0,
  css: false
}

export default Truncate