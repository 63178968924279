import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import NewsListItem from "./news-list-item"

const NewsList = () => {
  const data = useStaticQuery(graphql`
    query {
      allNodeArticle(
        filter: {
          status: {eq: true},
          publication_lte_now: {eq: false},
        }, 
        sort: {
          fields: created, 
          order: DESC
        }, 
        limit: 3
      ) {
        edges {
          node {
            id
            title
            created(formatString: "DD MMMM YYYY", locale: "fr")
            path {
              alias
            }
            body {
              value
              summary
            }
            field_publication
          }
        }
      }
    }
  `)
  return (
    <div id="news-list" className="news-list-front">
      <h2 className="title is-2">Infos pratiques</h2>
      <div className="columns is-multiline">
        {data.allNodeArticle.edges.map(({ node }, index) => (
          <NewsListItem key={index} node={node} columnType={12} withSummary={false} />
        ))}
      </div>
      <div className="more-link">
        <Link className="button is-rounded" to="/actualites">Toutes nos infos pratiques</Link>
      </div>
    </div>
  )
}

export default NewsList
