import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { PlayerContext, PODCAST_TYPE } from "./context/player-context"
import PropTypes from "prop-types"
import { Duration } from "./duration"

const PodcastListItem = ({ node, columnType, columnTypeWidescreen }) => {
  const playerContext = useContext(PlayerContext)
  const [_play, _setPlay] = useState(false)
  const { play, setPlay, audio, setEnabled, setContent, content } = playerContext

  useEffect(() => {
    if (content.type === PODCAST_TYPE && content.description === node.title && play) {
      audio.play();
      _setPlay(true)
    } else if (content.type === PODCAST_TYPE && content.description === node.title && !play) {
      audio.pause();
      _setPlay(false)
    } else {
      _setPlay(false)
    }
  }, [content, play, audio])

  const handleToggle = () => {
    if (node.relationships.audio.localFile.publicURL.split('/').pop() !== audio.src.toString().split('/').pop()) {
      audio.src = node.relationships.audio.localFile.publicURL
    }
    setContent({type: PODCAST_TYPE, description: node.title})
    setPlay(content.description === node.title ? !play : true)
    setEnabled(true)
  }

  return (
    <div className={`column${columnTypeWidescreen ? ` is-${columnTypeWidescreen}-widescreen` : ''} is-${columnType}`}>
      <article className="podcast">
        <div className="columns is-mobile">
          {node.relationships.image?.localFile && (
            <div className="column is-6 media">
              <Link to={node.path.alias}>
                {node.field_duree && (
                  <Duration time={node.field_duree.seconds} />
                )}
                <Img
                  fluid={node.relationships.image.localFile.childImageSharp.fluid}
                  alt={node.title}
                />
              </Link>
            </div>
          )}
          <div className={`column${node.relationships.image?.localFile ? ' is-6' : ' is-12'} content`}>
            <span>{node.created}</span>
            <h3 className="title is-3">
              <Link to={node.path.alias}>{node.title}</Link>
            </h3>
            <button
                onClick={handleToggle}
                className={_play ? "is-pause-button" : "is-play-button"}
            >
                {_play ? "Stop podcast" : "Play podcast"}
            </button>
          </div>
        </div>
      </article>
    </div>
  )
}

PodcastListItem.propTypes = {
  node: PropTypes.object.isRequired,
  columnType: PropTypes.number,
  columnTypeWidescreen: PropTypes.number
}

PodcastListItem.defaultProps = {
  columnType: 12,
}

export default PodcastListItem
