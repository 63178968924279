import React, { useEffect, useState } from "react"
import {Link} from 'gatsby';

export const HomeLastTracks = () => {

  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    fetch(`${process.env.API_URL}/jsonapi/node/titre_joue?page[limit]=6&sort=-field_heure`, {
      headers: new Headers({
        'Content-Type': 'application/vnd.api+json',
        'Accept': 'application/vnd.api+json',
        'Authorization': 'Basic `${process.env.API_AUTHORIZATION}`'
      }),
    })
      .then(response => response.json())
      .then(resultData => {
        resultData.data.map((track) => {
          let date  = new Date(track?.attributes.field_heure);
          track.attributes.field_heure = `${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}`
        });
        
        setTracks(resultData.data);  
      })
  }, []);

  return (
    <div id="tracks-list" className="has-background-primary last-tracks">
      <h2 className="title is-2">Titres diffusés</h2>
      <div className="columns is-multiline is-variable is-4 tracks">
        {tracks.map((track, index) => (
          <div key={index} className="column is-4-fullhd is-12 track">
            <div className="track-wrapper">
              <div className="columns is-mobile is-variable is-4 is-vcentered ">
                <div className="column">
                  <div className="track-hour">
                    {track?.attributes.field_heure}
                  </div>
                </div>
                <div className="column">
                  <div className="track-title">{track?.attributes.title}</div>
                  <div className="track-artist">{track?.attributes.field_artiste}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Link className="button is-rounded" to="/titres-diffuses/">Voir plus</Link>
    </div>
  )
}